import { useContext, useEffect, useState } from 'react';
import { Typography, Dropdown, Button, message, Tag, List, Space, Divider,notification } from 'antd';
import { Context, Utils } from 'shookt';
import { FaMoneyBillWave } from 'react-icons/fa';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import { Dashboard, Header, Timestamp, Desc, Banko } from '../../components';
import page from 'page';
import FeesForm from './FeesForm';
import feesGet from './feesGet';
import feesPost from './feesPost';
import feesUpdate from './feesUpdate';
// import paymentDelete from './paymentDelete';

const FeesView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [item, setItem] = useState({ order: {} });
  const toDrawer = Dashboard.toDrawer(dispatch);
 

  const loadData = () => {
    (async () => {
      const { status, data } = await feesGet(props.params.id);
      if (status === 200) {
        setItem(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);


  const enableFees= async () => {
    const { status } = await feesUpdate({
      is_enabled: !item.is_enabled,
      id: item.id,
    });
    if (status === 200) {
      setTimeout(() => {
        loadData();
      }, 100);
    }
  };

  useEffect(() => {
    const qry = JSON.parse(state.query);
    if (qry.success) {
      notification.success({
        message: 'BanKo Account Linked',
        description: 'You have successfully linked your BanKo account.'
      });
    } else if (qry.failed) {
      notification.error({
        message: 'BanKo Account Link Failed',
        description: 'Your BanKo account link failed. Please try again.'
      });
    }
  }, []);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/fees/all'
    >
   <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{item.name}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<FeesForm id={item.id} key={item.id} />, 'Update pricing information')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [{
                key: 'enable',
                label: item.is_enabled ? <>Set this fees <Tag>Offline</Tag></> : <>Set this fees as <Tag color='green'>LIVE</Tag></>,
                onClick: enableFees
              }]
            }}
          >
            Edit Fees
          </Dropdown.Button>
        )}
      />
      <Desc>
        <Desc.Item label='Status'>
          <Tag color={item.is_enabled ? 'green' : ''}>
            <Space align='center'>
            {item.is_enabled ? 'LIVE' : 'Offline'}
            </Space>
          </Tag>
        </Desc.Item>
        <Desc.Item label='Last Update'>
          {item.updated_at || '-'}
        </Desc.Item>
        <Desc.Item label='Last Update By'>
        {item.updated_by && item.updated_by.id && (Utils.formatName(item.updated_by))}
        </Desc.Item>
        <Desc.Item label='Description'>
          {item.description}
        </Desc.Item>
        <Desc.Item label='Created at'>
          <Timestamp stamp={item.created_at} />
        </Desc.Item>
        <Desc.Item label='Created By'>
        {item.created_by && item.created_by.id && (Utils.formatName(item.created_by))}
        </Desc.Item>
        <Desc.Item label='Amount (in Php)'>
          {item.amount}
        </Desc.Item>
        <Desc.Item label='Maximum Order'>
          {item.max_order}
        </Desc.Item>
        <Desc.Item label='Minimum Order'>
          {item.min_order}
        </Desc.Item>
        <Desc.Item label='Wholesaler/Distributor'>
          {item.wholesalers}
        </Desc.Item>
      </Desc>

      <Divider />
      <Banko />
      {/* {
        item.method === 'banko' && (
          <List
            dataSource={item.transactions}
            renderItem={t => {
              t.request = t.request || {};
              t.response = t.response || {};
              t.request.OpnbnkMsgBody = t.response.OpnbnkMsgBody || {};
              t.response.OpnbnkMsgBody = t.response.OpnbnkMsgBody || {};
              return (
                <Desc label={`Trace ID: ${t.request.OpnbnkMsgBody.traceId}`}>
                  <Desc.Item label='Type'>
                    {t.type}&nbsp;
                    {(() => {
                      if (t.type === '203') {
                        return 'Cancel request';
                      }
                      if (t.type === '204') {
                        return 'Reversal request';
                      }
                      if (t.type === '005') {
                        return 'Transfer request';
                      }
                    })()}
                  </Desc.Item>
                  <Desc.Item label='Date'>
                    {t.response.OpnbnkMsgBody.txDt}
                  </Desc.Item>
                  <Desc.Item label='Time'>
                    {t.response.OpnbnkMsgBody.txHms}
                  </Desc.Item>

                  <Desc.Item label='Response ID'>
                    {t.response.OpnbnkMsgBody.rspnsId}
                  </Desc.Item>
                  <Desc.Item label='Message'>
                    {t.response.OpnbnkMsgBody.msgCntnt}
                  </Desc.Item>
                  {
                    t.request.OpnbnkMsgBody.txAmt && (
                      <Desc.Item label='Amount'>
                        ₱{Utils.toMonetary(t.request.OpnbnkMsgBody.txAmt)}
                      </Desc.Item>
                    )
                  }
                </Desc>
              );
            }}
          />
        )
      } */}

    </Dashboard>
  );
};

export default FeesView;
