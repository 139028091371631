import { FaTag, FaMoneyBillWave, FaStore,FaDollarSign, FaBoxOpen, FaListAlt, FaTruck, FaShoppingCart, FaUserAlt, FaGraduationCap, FaShoppingBasket,FaUpload } from 'react-icons/fa';

const menuItems = state => {
  const menu = [];
  if (state.user && state.user.privileges) {
    const { privileges } = state.user;

    if (privileges.order) {
      menu.push({
        icon: <FaShoppingCart />,
        label: <a href='/order/all'>Orders</a>,
        key: '/order/all'
      });
    }
    if (privileges.payments) {
      menu.push({
        icon: <FaMoneyBillWave />,
        label: <a href='/payments/all'>Payments</a>,
        key: '/payments/all'
      });
    }
    if (privileges.delivery) {
      menu.push({
        icon: <FaTruck />,
        label: <a href='/delivery/all'>Deliveries</a>,
        key: '/delivery/all'
      });
    }
    if (privileges.company) {
      menu.push({
        icon: <FaStore />,
        label: <a href='/company/all'>Distributors</a>,
        key: '/company/all'
      });
    }
    if (privileges.category) {
      menu.push({
        icon: <FaListAlt />,
        label: <a href='/category'>Categories</a>,
        key: '/category'
      });
    }
    if (privileges.inventory) {
      menu.push({
        icon: <FaBoxOpen />,
        label: <a href='/inventory/all'>Inventory</a>,
        key: '/inventory/all'
      });
    }
    if (privileges.product) {
      menu.push({
        icon: <FaShoppingBasket />,
        label: <a href='/product/all'>Products</a>,
        key: '/product/all'
      });
    }
    if (privileges.pricing) {
      menu.push({
        icon: <FaTag />,
        label: <a href='/pricing/all'>Pricing</a>,
        key: '/pricing/all'
      });
    }
    if (privileges.user) {
      menu.push({
        icon: <FaUserAlt />,
        label: <a href='/user/all'>Users</a>,
        key: '/user/all'
      });
    }
    if (privileges.role) {
      menu.push({
        icon: <FaGraduationCap />,
        label: <a href='/role/all'>Roles</a>,
        key: '/role/all'
      });
    }
    if (privileges.pricing) {
      menu.push({
        icon: <FaUpload />,
        label: <a href='/upload/all'>Upload</a>,
        key: '/upload/all'
      });
    }
    if (privileges.pricing) {
      menu.push({
        icon: <FaDollarSign />,
        label: <a href='/fees/all'>Fees</a>,
        key: '/fees/all'
      });
    }
  }

  return menu;
};

export default menuItems;
