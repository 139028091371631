import axios from 'axios';

const FeesGetComp = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: '/fees/company'
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default FeesGetComp;
