import axios from 'axios';
import { Utils } from 'shookt';

const orderGet = async (id, params) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/order/${id || 'all'}?${Utils.qs(params)}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

orderGet.methodCodeToName = method => {
  if (!method) {
    return '';
  }
  const names = {
    cod: 'Cash on delivery',
    banko: 'BanKo',
    icl: 'InstaCashKoLine'
  };
  return names[method.toLowerCase()];
};

orderGet.typeStatus = {
  order: [
    { label: 'To Pay', value: 'topay' },
    { label: 'To Ship', value: 'toship' },
    { label: 'To Receive', value: 'toreceive' },
    { label: 'Received', value: 'received' },
    { label: 'Canceled', value: 'canceled' }
  ],
  delivery: [
    { label: 'Pending', value: 'pending' },
    { label: 'In Transit', value: 'intransit' },
    { label: 'Delivered', value: 'delivered' },
    { label: 'Return', value: 'return' }
  ],
  payment: [
    { label: 'Pending', value: 'pending' },
    { label: 'Reversed', value: 'reversed' },
    { label: 'Failed', value: 'failed' },
    { label: 'Paid', value: 'paid' }
  ]
};

orderGet.statusToLabel = status => {
  if (!status) {
    return 'default';
  }
  const labels = {
    topay: 'To Pay',
    toship: 'To Ship',
    toreceive: 'To Receive',
    intransit: 'In Transit',
    delivered: 'Delivered',
    reversed: 'Reversed',
    received: 'Received',
    canceled: 'Canceled',
    assigned: 'Assigned',
    unassigned: 'Unassigned',
    pending: 'Pending',
    return: 'Return',
    failed: 'Failed',
    paid: 'Paid'
  };
  return labels[status.toLowerCase()];
};

orderGet.statusToColor = status => {
  if (!status) {
    return 'default';
  }
  const colors = {
    unassigned: 'blue',
    assigned: 'green',
    failed: 'red',
    paid: 'green',
    topay: 'magenta',
    toship: 'volcano',
    intransit: 'orange',
    delivered: 'green',
    toreceive: 'gold',
    received: 'lime',
    reversed: 'purple',
    canceled: '#ccc',
    pending: 'default',
    return: 'geekblue'
  };
  return colors[status.toLowerCase()];
};

orderGet.toStatusColor = status => {
  return (() => orderGet.statusToColor(status))();
};

export default orderGet;
