import axios from 'axios';

const uploadDL = async (params) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/upload/download',
      params: params, // Include parameters in the GET request
    });
    return response.data.presignedUrl; // Return the presigned URL
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error; // Throw the error for handling in the calling code
  }
};

export default uploadDL;
