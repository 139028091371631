import { useContext, useEffect, useState } from 'react';
import { List, Card, Empty, ConfigProvider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Context, Utils } from 'shookt';
import { useWindowSize } from 'rooks';
import { Dashboard, Header, Ellipsis, Picture } from '../../components';
import pagejs from 'page';
import qs from 'qs';
import s from './market.module.less';
import marketGet from './marketGet';
import MarketHead from './MarketHead';
import MarketButton from './MarketButton';
import CompanyHome from '../../pages/company/CompanyHome';

export const sortOptions = {
  newest: { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
  oldest: { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
  updated: { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
  nameaz: { label: 'Name A-Z', value: { column: 'name', order: 'asc' } },
  nameza: { label: 'Name Z-A', value: { column: 'name', order: 'desc' } }
};

const Market = props => {
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [findout, setFindout] = useState(null);
  const { innerWidth } = useWindowSize();
  
  const queryObj = JSON.parse(state.query);
  const query = qs.stringify(queryObj);
  const loadData = () => {
    setLoading(true);
    dispatch({ upsert: { loading: true } });
    if (findout) {
      clearTimeout(findout);
    }
    setFindout(setTimeout(() => {
      (async () => {
        const query = JSON.parse(state.query);
        Utils.cleanQuery(query, sortOptions);
        if (query.sort) {
          query.sort = [sortOptions[query.sort].value];
        }
        query.category = query.category || 0;

        if (query.distributor) {
          query.distributor = Array.isArray(query.distributor)
            ? query.distributor.join('&distributor=')
            : query.distributor;
        }
        else {
          const compLabel = sessionStorage.getItem('_company'); // Retrieve the stored data
          const selectedData = compLabel ? JSON.parse(compLabel) : []; // Parse the JSON string
          const distDt = selectedData.map((item) => item.value);
        
          if (distDt.length > 0) {
            query.distributor = distDt.join('&distributor=');
          } else {
            delete query.distributor;
          }
        }
      if (query.distributor === 0) {
        delete query.distributor;
    }
        
        query.pageSize = state.pageSize;
        if (innerWidth > 1600) {
          query.pageSize = 24;
        }
        if (innerWidth < 768 && innerWidth >= 576) {
          query.pageSize = 21;
        }
        const { data } = await marketGet('all', query);
        dispatch({
          upsert: {
            market: data.data || [],
            marketPage: data.page || 1,
            marketTotal: data.total || 0,
            pageSize: query.pageSize,
          }
        });
        setLoading(false);
      })();
    }, 250));
  };

  const upChange = (vals = {}) => {
    if (findout) {
      clearTimeout(findout);
    }
    setFindout(setTimeout(() => {
      const payload = {
        ...JSON.parse(state.query),
        ...vals
      };
      if (payload.page === 1) {
        delete payload.page;
      }
       if (!payload.category) {
        delete payload.category;
      }
      if (!payload.company) {
        delete payload.company;
      }
      if (!payload.province) {
        delete payload.province;
      }
      if (!payload.find) {
        delete payload.find;
      }
      if (!payload.sort) {
        delete payload.sort;
      }
      if (!payload.distributor) {
        // Manually construct the distributor query string
        const distributorQueryString = Array.isArray(payload.distributor)
          ? payload.distributor.join('&distributor=')
          : payload.distributor;
        path += `?distributor=${distributorQueryString}`;
        delete payload.distributor;
        
      }
      
      let path = '/';
      if (Object.keys(payload).length) {
        path = `/?${qs.stringify(payload)}`;
      }
    
      pagejs(path);
    }, 250));
  };

  useEffect(() => {
    (async () => {
      if (state.user && state.user.type !== 'public') {
        return;
      }

      await loadData();
      dispatch({ delete: ['reload'] });
    })();
  }, [state.query, state.reload]);

  if (state.user && state.user.type !== 'public') {
    return <CompanyHome />;
  }

  let category = 'All categories';
  if (queryObj.category && state.categoriesObj && state.categoriesObj[queryObj.category]) {
    category = state.categoriesObj[queryObj.category].name;
  }

  return (
    <Dashboard>
      <MarketHead />
      <Header
        noTop
        title={(
          <>
            <MarketButton />
            <Ellipsis className={s.header}>{category}</Ellipsis>
          </>
        )}
      />
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 60 }}
            description={<>Sorry, we have found no product that matched your query.</>}
          />
        )}
      >
        <List
          grid={{ gutter: [16, 0], xs: 2, sm: 3, md: 4, lg: 4, xl: 5, xxl: 6 }}
          loading={{ spinning: loading, indicator: <LoadingOutlined spin /> }}
          dataSource={state.market || []}
          renderItem={item => {
            let pricing = 'No price yet';
            if (Array.isArray(item.pricing)) {
              let lowest;
              let highest;
              item.pricing.forEach(p => {
                if (!lowest || p.unit_price < lowest) {
                  lowest = p.unit_price;
                }
                if (!highest || p.unit_price > highest) {
                  highest = p.unit_price;
                }
              });
              pricing = '';
              if (lowest) {
                pricing = `₱${Utils.toMonetary(lowest)}`;
              }
              if (highest && highest !== lowest) {
                if (lowest) {
                  pricing = `${pricing} - `;
                }
                pricing = `${pricing}₱${Utils.toMonetary(highest)}`;
              }
            }
            return (
              <a href={`/market/${item.id}?${query}`}>
                <List.Item>
                  <Card
                    hoverable
                    className='market-card product'
                    cover={<Picture width='100%' height='180px' alt={item.name} src={item.avatar} preview={false} />}
                  >
                    <Ellipsis noHover className={s.name}>
                      {item.name}
                    </Ellipsis>
                    <Ellipsis className={s.category} type='secondary' noHover>
                      {(item.category && item.category.name) || <>&nbsp;</>}
                    </Ellipsis>
                    <Ellipsis className={`${s.price} ${item.pricing ? s.primary : ''}`} type={item.pricing ? '' : 'secondary'} noHover>
                      {pricing}
                    </Ellipsis>
                    <Ellipsis className={s.company} noHover>
                      {item.company.name}
                    </Ellipsis>
                  </Card>
                </List.Item>
              </a>
            );
          }}
          pagination={{
            position: 'bottom',
            showTitle: false,
            showSizeChanger: false,
            onChange: page => upChange({ page }),
            className: s.pagination,
            pageSize: state.pageSize || 1,
            current: state.marketPage || 1,
            total: state.marketTotal || 0,
            size: 'small'
          }}
        />
      </ConfigProvider>
    </Dashboard>
  );
};

export default Market;
