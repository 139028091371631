import { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { Context, Utils } from 'shookt';
import page from 'page';
import feesGet from './feesGet';
import feesPost from './feesPost';
import FeesGetComp from './feesGetComp';

const FeesForm = (props) => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedWholesalers, setSelectedWholesalers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [wholesalers, setWholesalers] = useState([]);
  const [isMaxOrderLimit, setIsMaxOrderLimit] = useState(false);
  const [isMinOrderLimit, setIsMinOrderLimit] = useState(false);

  const saveFees = async (payload) => {
    try {
      const { status, data } = await feesPost(payload);
      if (status === 200) {
        if (props.id) {
          dispatch({ upsert: { reload: true } });
        } else {
          page(`/fees/all`);
          window.location.reload();
        }
        dispatch({ delete: ['drawer'] });
      }
    } catch (error) {
      console.error('Error saving fees:', error);
    }
  };

  const onFinish = async (values) => {
    

     // Determine wholesalers to send
  const wholesalersToSend = selectedWholesalers.length > 0 
  ? selectedWholesalers.map(wholesaler => `${wholesaler.id}-${wholesaler.name}`) // Format as "id-name"
  : [`${state.user.company_id}-${state.user.company.name}`]; // Format for company if no wholesalers selected

    const payload = {
      name: values.fee_name.trim(),
      description: values.fee_description.trim(),
      amount: values.fee_amt.trim(),
      // Check if Fee_max and Fee_min exist before accessing them
      max_order: isMaxOrderLimit ? '999999' : values.fee_max?.trim(),
      is_max_order_limit: values.Cb_max || false,
      min_order: isMinOrderLimit ? '1' : values.fee_min?.trim(),
      is_min_order_limit: values.Cb_min || false,
      wholesalers: wholesalersToSend,
      company_id: state.user.company_id,
      created_by: state.user ? state.user.id : null,
    };
    if (values.newprici_company) {
      payload.company_id = values.newprici_company;
    }
    if (props.id) {
      payload.id = props.id;
    }
    // Add console comment for the data to be sent for saving
  
    await saveFees(payload);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleWholesalerChange = (checkedValues) => {
    if (checkedValues.includes("all")) {
      setSelectedWholesalers(wholesalers.map(wholesaler => ({
        id: wholesaler.id,
        name: wholesaler.name
      })));
      setSelectAll(true);
    } else {
      const selectedIds = checkedValues.filter(value => value !== "all");
      if (selectedIds.length === 0) {
        setSelectedWholesalers([]);
      } else {
        setSelectedWholesalers(wholesalers.filter(wholesaler => selectedIds.includes(wholesaler.id)));
      }
      setSelectAll(selectedIds.length === wholesalers.length);
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    if (checked) {
      // Add all wholesaler IDs to selectedWholesalers
      setSelectedWholesalers(wholesalers.map(wholesaler => wholesaler.id));
    } else {
      // Clear all selected wholesalers
      setSelectedWholesalers([]);
    }
  };



  
  const fetchWholesalers = async () => {
    try {
      // Check user type
      if (state.user.type === 'admin') {
        // Run the initial logic if the user type is 'admin'
        const { status, data } = await FeesGetComp();
    
        if (status === 200 && data.filteredResults && Array.isArray(data.filteredResults)) {
          setWholesalers(data.filteredResults);
        } else {
          setWholesalers([]); // Ensure wholesalers is always an array
        }
      } else {
        // For non-admin users, set wholesalers to company details
        setWholesalers([
          { id: state.user.company.id, name: state.user.company.name }
        ]);
      }
    } catch (error) {
      console.error('Error fetching wholesalers:', error);
      setWholesalers([]); // Handle error by setting an empty array
    }
  };
  
 
  

  useEffect(() => {

    fetchWholesalers();

    const fetchData = async () => {
      if (props.id) {
        const { status, data } = await feesGet(props.id);
        if (status === 200) {
          form.setFieldsValue({
            newprici_company: data.company_id,
            fee_name: data.name,
            fee_description: data.description,
            fee_amt: data.amount,
            fee_max: data.max_order,
            Cb_max: data.is_max_order_limit,
            fee_min: data.min_order,
            Cb_min: data.is_min_order_limit,
            wholesalers: data.wholesalers
          });
          setIsMaxOrderLimit(data.is_max_order_limit);
          setIsMinOrderLimit(data.is_min_order_limit);
        }
      }
    };
    fetchData();
  }, [form, props.id]);


  const handleMaxOrderCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsMaxOrderLimit(isChecked);
    form.setFieldsValue({ fee_max: isChecked ? '' : form.getFieldValue('fee_max') });
  };

  const handleMinOrderCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsMinOrderLimit(isChecked);
    form.setFieldsValue({ fee_min: isChecked ? '' : form.getFieldValue('fee_min') });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='Fee name'
        name='fee_name'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a Fee name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Fee name'
        />
      </Form.Item>
      <Form.Item
        label='Description'
        name='fee_description'
        rules={[{
          max: 300,
          required: true,
          whitespace: true,
          message: 'Please enter a description'
        }]}
      >
        <Input.TextArea placeholder='Fee Description' maxLength={300} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>
      <Form.Item
        label='Fee (in PhP)'
        name='fee_amt'
        rules={[{
          required: true,
          whitespace: true,
          pattern: /^[0-9]*(\.[0-9]+)?$/,
          message: 'Please enter a valid numeric amount'
        }]}
      >
        <Input
          minLength={1}
          maxLength={10}
          placeholder='Fee amount'
        />
      </Form.Item>

      <Form.Item label="Threshold Amount (in Php)">
        <Form.Item
          label='Maximum Order'
          name='fee_max'
          rules={[{
            required: false,
            whitespace: true,
            pattern: /^[0-9]*(\.[0-9]+)?$/,
            message: 'Please enter a valid numeric amount'
          }]}
        >
          <Input
            minLength={1}
            maxLength={10}
            placeholder='Amount'
            disabled={isMaxOrderLimit}
          />
        </Form.Item>
        <Form.Item
          name='Cb_max'
          valuePropName='checked'
          noStyle
        >
          <Checkbox name='Cb_max' onChange={handleMaxOrderCheckboxChange}>No Limit</Checkbox>
        </Form.Item>

        <Form.Item
          label='Minimum Order'
          name='fee_min'
          rules={[{
            required: false,
            whitespace: true,
            pattern: /^[0-9]*(\.[0-9]+)?$/,
            message: 'Please enter a valid numeric amount'
          }]}
        >
          <Input
            minLength={1}
            maxLength={10}
            placeholder='Amount'
            disabled={isMinOrderLimit}
          />
        </Form.Item>
        <Form.Item
          name='Cb_min'
          valuePropName='checked'
          noStyle
        >
          <Checkbox name='Cb_min' onChange={handleMinOrderCheckboxChange}>No Limit</Checkbox>
        </Form.Item>
      </Form.Item>
      {state.user.type === 'admin' && (
  <Form.Item label="Select Wholesaler/Distributor">
    <Checkbox.Group value={selectedWholesalers.map(wholesaler => wholesaler.id)} onChange={handleWholesalerChange}>
      <Row>
        <Col span={24}>
          <Checkbox value="all" checked={selectAll} onChange={handleSelectAll}>All</Checkbox>
        </Col>
        {wholesalers.map(wholesaler => (
          <Col span={24} key={wholesaler.id}>
            <Checkbox value={wholesaler.id}>{wholesaler.name}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  </Form.Item>
)}
      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {props.id ? 'Save changes' : 'Add new Fee'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FeesForm;
