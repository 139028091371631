import React, { useEffect, useState, useRef } from 'react';
import { Header } from '..';
import { Utils } from 'shookt';
import s from './ServiceFee.module.less';
import GetFee from './GetFee'; // Adjust the import path as needed

const ServiceFee = ({ params, onFeesTotal, paramTotal }) => {
  const [fees, setFees] = useState([]);
  const [error, setError] = useState(null);
  const paramsRef = useRef(null);

  useEffect(() => {
    const fetchFees = async () => {
      if (params !== null && params !== paramsRef.current) {
        paramsRef.current = params;
        const result = await GetFee(params, paramTotal);

        if (result.error) {
          setError(result.error);
        } else {
          // Access the filteredResults array from the result object
          if (result.filteredResults && Array.isArray(result.filteredResults)) {
            const filteredFees = result.filteredResults.map(fee => ({
              id: fee.id,
              name: fee.name,
              amount: parseFloat(fee.amount),
            }));
            setFees(filteredFees);

            const totalFees = filteredFees.reduce((acc, fee) => acc + fee.amount, 0);
            onFeesTotal(totalFees, filteredFees);
          } else {
            setError('Unexpected response format');
          }
        }
      }
    };

    fetchFees();
  }, [params, onFeesTotal, paramTotal]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {fees.map(fee => (
        <Header
          key={fee.id}
          level={5}
          title={fee.name}
          extra={<span className={s[fee.id]}>{`₱${Utils.toMonetary(fee.amount)}`}</span>}
        />
      ))}
    </div>
  );
};

export default ServiceFee;
