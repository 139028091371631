import { useState, useContext, useEffect } from 'react';
import { Button, Tag } from 'antd';
import { Context } from 'shookt';
import { Dashboard, DataList, Header, Ellipsis } from '../../components';
import feesGet from './feesGet';
import FeesForm from './FeesForm';

const FeesList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);
  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      
      setTimeout(async () => {
        // Construct the query parameters
        
          const queryParams = {
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        };
  
        // Add company parameter only if user is not an admin
        if (!state.user.is_admin) {
          queryParams.company = state.user.company_id;
        }
  
        // Fetch data with the constructed query parameters
        const { data } = await feesGet('all', queryParams);
  
        dispatch({ upsert: { fees: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Fees Groups'
        extra={(
          <Button
            type='primary'
            onClick={toDrawer(<FeesForm />, 'Create new Fees')}
          >
            Add new Fees
          </Button>
        )}
      />

      <DataList
        values={values}
        storeKey='fees'
        onChange={setParams}
        countLabel='fees groups'
        dataSource={state.fees}
        rowUrl={item => `/fees/${item.id}`}
        // extra={CompanySelect.get(state, { allowClear: true, storeKey: 'pricing', onChange: setCompany })}
        titleHandler={item => (<Ellipsis>{item.name}</Ellipsis>)}
        bodyHandler={item => (
          <Ellipsis type='secondary' className='lisht-content'>
            <Tag color={item.is_enabled ? 'green' : ''}>{item.is_enabled ? 'LIVE' : 'Offline'}</Tag>
            {item.description}
          </Ellipsis>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default FeesList;
