import axios from 'axios';

const GetFee = async (params, paramTotal, paramOrder) => {
  try {
    // Create the query parameters object
    const queryParams = { params, paramTotal };
    
    // Conditionally add paramOrder to queryParams if it is not null or undefined
    if (paramOrder !== null && paramOrder !== undefined) {
      queryParams.paramOrder = paramOrder;
    }

    // Perform the GET request
    const response = await axios({
      method: 'GET',
      url: '/fees/service',
      params: queryParams, // Include parameters in the GET request
    });

    return response.data; // Return the data part of the response
  } catch (error) {
    // Log the full error object for more details
    console.error('Full error object:', error);

    if (error.response) {
      // Server responded with a status code outside the 2xx range
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
      return { error: error.response.data.error || 'Internal Server Error' };
    } else if (error.request) {
      // No response received from server
      console.error('Error request data:', error.request);
      return { error: 'No response received from server' };
    } else {
      // Error setting up the request
      console.error('Error message:', error.message);
      return { error: error.message || 'Internal Server Error' };
    }
  }
};

export default GetFee;
