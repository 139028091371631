import { useContext, useEffect, useState } from 'react';
import { Form, InputNumber, List, Button, Empty, ConfigProvider, message } from 'antd';
import { Context, Utils } from 'shookt';
import { Header, Desc } from '../';
import availmentGet from './availmentGet';
import { Dashboard } from '../../components'

const Availment = props => {
  const [state, dispatch] = useContext(Context);
  const toDrawer = Dashboard.toDrawer(dispatch);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  let user = props.user || state.user || {};
  let availment = state.availmentAccount || {};
  let link = 'availment';

  const loadData = async () => {
    setLoading(true);
    const { status, data } = await availmentGet({ state: 'info' });
    if (status === 200) {
      availment = data;
      dispatch({ upsert: { availmentAccount: data, hasAvailment: true } });
    } else {
      message.error('Unable to load Availment account details at this time. Please try again later.');
    }
    setLoading(false);
  };

  const onFinish = async values => {
    let { icl_value: icl } = values;
    icl = icl.replace('₱ ', '')

    if (icl.includes(',')) {
      icl = icl.replace(',', '')
    }

    if (parseFloat(icl) >= 500) {
      window.location.replace(`/api/availment?state=availment&txAmt=${icl}`);
      form.setFieldValue('icl_value', '');
      dispatch({ delete: ['drawer'] });
      return
    } else {
      message.error('Value must not be less than ₱ 500')
    }
  }

  useEffect(() => {
    (async () => {
      if (props.autoload) {
        await loadData();
      }
    })();
  }, []);

  return (
    <>
      {
        props.noHead || (
          <Header
            noBottom
            title='Availment Account'
          />
        )
      }

      { availment.arrStsCd === 'S' && 
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 60 }}
          description={'No InstaCashKoLine account linked.'}
        />
      }
      { availment.arrStsCd !== 'S' && 
        <ConfigProvider>
          <List.Item>
            <List.Item.Meta
              description={(
                <Desc>
                  <Desc.Item label='Account name'>
                    {availment.pdNm ? availment.pdNm : ''}
                  </Desc.Item>
                  <Desc.Item label='Account number'>
                    {availment.acctNbr ? availment.acctNbr : ''}
                  </Desc.Item>
                  <Desc.Item label='Balance'>
                    ₱{Utils.toMonetary(availment.lastBal ? availment.lastBal : 0)}
                  </Desc.Item>
                  <Desc.Item label='Available limit'>
                    ₱{Utils.toMonetary(availment.avlblLmtAmt ? availment.avlblLmtAmt : 0)}
                  </Desc.Item>
                  <Desc.Item label='Limit'>
                    ₱{Utils.toMonetary(availment.lmtAmt ? availment.lmtAmt : 0)}
                  </Desc.Item>
                  <Desc.Item label='Status'>
                    {availment.arrStsCd === 'S' ? 'Suspended' : 'Active'}
                  </Desc.Item>
                  <Desc.Item label='Maturity Date'>
                    {availment.mtrtyDt ? availment.mtrtyDt : ''}
                  </Desc.Item>
                </Desc>
              )}
            />
          </List.Item>
        </ConfigProvider>
      }

      {/* { props.availmentOpt && availment.arrStsCd !== 'S' && 
        <>
          <Empty
            imageStyle={{ display: 'none' }}
            description=''
          >
            <Button 
              type='default'
              onClick={
                toDrawer(
                  <Form
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item label='Input desired loan amount' name='icl_value'>
                      <InputNumber maxLength={14} step="0.01" formatter={(value) => `₱ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} stringMode />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        block
                        type='primary'
                        htmlType='submit'
                        disabled={state.loading}
                        loading={state.loading}
                      >
                        Confirm
                      </Button>
                    </Form.Item>
                  </Form>,
                'Avail InstaCashLine')
              }>
              Avail InstaCashLine
            </Button>
          </Empty>
        </>
      } */}
    </>
  );
};

Availment.loadAccounts = async () => {
  const { data } = await availmentGet({ state: 'info' });
  return data || [];
};

export default Availment;
