import { useContext, useEffect, useState } from 'react';
import { Form, TreeSelect } from 'antd';
import { Context } from 'shookt';
import s from './categoryselect.module.less';
import categoryGet from '../../pages/category/categoryGet';

const CategorySelect = props => {
  const [state, dispatch] = useContext(Context);
  const [category, setCategory] = useState(null);

  const onChange = (val = null) => {
    props.onChange && props.onChange(val || 0);
    if (val) {
      props.storeKey && sessionStorage.setItem(`${props.storeKey}_category`, val);
    } else {
      props.storeKey && sessionStorage.removeItem(`${props.storeKey}_category`);
    }
    setCategory(val);
  };

  useEffect(() => {
    (async () => {
      if (!state.categories) {
        const { status, data, objData } = await categoryGet();
        if (status === 200) {
          dispatch({
            upsert: {
              categories: data,
              categoriesObj: objData
            }
          });
        }
      }
      const catg = sessionStorage.getItem(`${props.storeKey}_category`);
      !props.noOnloadChange && props.onChange && props.onChange(catg || 0);
      if (props.storeKey) {
        setCategory(catg ? String(catg) : null);
      }
    })();
  }, []);

  let treeValue;
  if ('value' in props) {
    if (props.value && state.categoriesObj && state.categoriesObj[props.value]) {
      treeValue = props.value;
    
    }
  } else {
    treeValue = category;
  }

  let body = (
    <TreeSelect
      showSearch
      value={treeValue}
      style={props.style || {}}
      onChange={onChange}
      treeData={state.categories}
      disabled={props.disabled}
      className={s.select}
      allowClear={props.allowClear || false}
      placeholder='Select category'
      treeNodeFilterProp='children'
      filterTreeNode={(input, treeNode) => treeNode.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      dropdownStyle={{ minWidth: 320, overflow: 'auto' }}
      popupClassName='category-select'
    />
  );
  if (props.name) {
    body = (
      <Form.Item
        name={props.name}
        label={props.label || 'Category'}
        rules={props.rules || []}
      >
        {body}
      </Form.Item>
    );
  }

  return body;
};

export default CategorySelect;
