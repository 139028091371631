import { useContext, useEffect, useState } from 'react';
import { Typography, Dropdown, Button, message, Tag, List, Space, Divider } from 'antd';
import { Context, Utils } from 'shookt';
import { FaMoneyBillWave } from 'react-icons/fa';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import { Dashboard, Header, Timestamp, Desc } from '../../components';
import page from 'page';
import orderGet from '../order/orderGet';
import paymentGet from './paymentGet';
import paymentDelete from './paymentDelete';

const PaymentsView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [item, setItem] = useState({ order: {} });

  const reversePayment = async () => {
    const { status, data } = await paymentDelete(props.params.id);
    if (status === 200) {
      setTimeout(() => {
        loadData();
      }, 100);
      message.success('Payment reversed successfully');
    } else {
      message.error(data.message);
    }
  };

  const loadData = () => {
    (async () => {
      const { status, data } = await paymentGet(props.params.id);
      if (status === 200) {
        setItem(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/payments/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{state.screens.lg && orderGet.methodCodeToName(item.method)} {item.id}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={() => page(`/order/${item.order_id}`)}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [{
                key: 'refund',
                label: 'Reverse payment',
                disabled: item.method === 'cod' || item.order.status_payment !== 'paid',
                onClick: reversePayment
              }]
            }}
          >
            View order
          </Dropdown.Button>
        )}
      />
      <Desc>
        <Desc.Item label='Status'>
          <Tag color={orderGet.toStatusColor(item.order && item.order.status_payment)}>
            <Space align='center'>
              <FaMoneyBillWave />
              {orderGet.statusToLabel(item.order && item.order.status_payment)}
            </Space>
          </Tag>
        </Desc.Item>
        <Desc.Item label='Reference no.'>
          {item.code || '-'}
        </Desc.Item>
        <Desc.Item label='Created at'>
          <Timestamp stamp={item.created_at} />
        </Desc.Item>
        <Desc.Item label='Order ID'>
          {item.order.code}
        </Desc.Item>
        <Desc.Item label='ID'>
          {item.id}
        </Desc.Item>
        <Desc.Item label='Created by'>
          {item.created_by && item.created_by.id && (Utils.formatName(item.created_by))}
        </Desc.Item>
      </Desc>

      <Divider />

      {
        ['banko', 'icl'].includes(item.method) && (
          <List
            dataSource={item.transactions}
            renderItem={t => {
              t.request = t.request || {};
              t.response = t.response || {};
              t.request.OpnbnkMsgBody = t.response.OpnbnkMsgBody || {};
              t.response.OpnbnkMsgBody = t.response.OpnbnkMsgBody || {};
              return (
                <Desc label={`Trace ID: ${t.request.OpnbnkMsgBody.traceId}`}>
                  <Desc.Item label='Type'>
                    {t.type}&nbsp;
                    {(() => {
                      if (t.type === '203') {
                        return 'Cancel request';
                      }
                      if (t.type === '204') {
                        return 'Reversal request';
                      }
                      if (t.type === '206') {
                        return 'Availment request';
                      }
                      if (t.type === '005') {
                        return 'Transfer request';
                      }
                    })()}
                  </Desc.Item>
                  <Desc.Item label='Date'>
                    {t.response.OpnbnkMsgBody.txDt}
                  </Desc.Item>
                  <Desc.Item label='Time'>
                    {t.response.OpnbnkMsgBody.txHms}
                  </Desc.Item>

                  <Desc.Item label='Response ID'>
                    {t.response.OpnbnkMsgBody.rspnsId}
                  </Desc.Item>
                  <Desc.Item label='Message'>
                    {t.response.OpnbnkMsgBody.msgCntnt}
                  </Desc.Item>
                  {
                    t.request.OpnbnkMsgBody.txAmt && (
                      <Desc.Item label='Amount'>
                        ₱{Utils.toMonetary(t.request.OpnbnkMsgBody.txAmt)}
                      </Desc.Item>
                    )
                  }
                </Desc>
              );
            }}
          />
        )
      }

    </Dashboard>
  );
};

export default PaymentsView;
