import { useEffect, useContext, useState } from 'react';
import { List, Space, Typography, Collapse, Tag, Dropdown, Select, message } from 'antd';
import { Dashboard, Banko, Address, Header, DataList, Created, Spacer, Variations, Desc, Avatar, Timestamp, Ellipsis } from '../../components';
import { AiOutlineDown } from 'react-icons/ai';
import { Context, GoogleMap, Utils } from 'shookt';
import s from './market.module.less';
import orderGet from '../order/orderGet';
import marketOrderGet from './marketOrderGet';
import marketOrderPost from './marketOrderPost';

const MyOrders = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [status, setStatus] = useState(null);
  const [panel, setPanel] = useState(null);
  const [availmentFeeAmount, setAvailmentFeeAmount] = useState(0);
  const [feesByOrderId, setFeesByOrderId] = useState({});
  const [feesTotal, setFeesTotal] = useState(0);

  const orderAction = (event, order) => {
    const callBanko = () => window.location.replace(`/api/banko?state=${event === 'retry' ? (order.method_payment === 'banko' ? 'transfer' : 'icl') : 'cancel'}`);
    const callApi = async action => {
      const { status } = await marketOrderPost({ id: order.id, action });
      if (status === 200) {
        dispatch({ upsert: { reload: true } });
      }
    };
    return async () => {
      if (event === 'receive' || (event === 'cancel' && (
        (order.method_payment !== 'cod' && order.status === 'topay') ||
        (order.method_payment === 'cod' && ['toship', 'topay'].includes(order.status))))) {
        await callApi('update');
      } else {
        await callApi('checkout');
        if (['banko', 'icl'].includes(order.method_payment)) {
          const accts = await Banko.loadAccounts();
          if (accts.accounts.length) {
            callBanko();
          } else {
            message.error('Please link your Banko Account first');
          }
        }
        // TODO: add other payment method handlers
      }
    };
  };

  const extractAndSetFees = (orderId, logs) => {
    if (!Array.isArray(logs)) {
        return { fees: [], total: 0 };
    }

    // Flatten and filter logs to extract fees
    const extractedFees = logs
        .filter(logEntry => logEntry?.log && Array.isArray(logEntry.log)) // Ensure 'log' is an array and not null/undefined
        .flatMap(logEntry => logEntry.log)
        .filter(log => log.name && log.amount && log.id !== 0);

    // Calculate the total amount of fees
    const total = extractedFees.reduce((sum, fee) => sum + fee.amount, 0);

    return { fees: extractedFees, total };
  };
  
  useEffect(() => {
    if (state.orders && Array.isArray(state.orders)) {
      const extractFeesFromOrders = () => {
        state.orders.forEach(order => {
          if (order.logs && Array.isArray(order.logs)) {
            extractAndSetFees(order.logs);
          } else {
            // console.error('Invalid logs in order:', order.logs);
          }
        });
      };

      extractFeesFromOrders();
    } else {
      // console.error('Invalid orders data:', state.orders);
    }
  }, [state.orders]);

  const loadData = async () => {
    dispatch({ upsert: { loading: true } });
    try {
      const { data } = await marketOrderGet('all', {
        find: params.find,
        sort: params.sort,
        page: params.page || 1,
        pageSize: state.pageSize,
        status
      });

      // Check if 'data' and 'data.data' are valid
      if (data && Array.isArray(data.data)) {
        // Update orders in state
        dispatch({ upsert: { orders: data.data } });
        setValues(data);

        // Extract and set fees for each order
        const feesByOrderId = {};
        const feesTotal = {};
        let totalFees = 0;

        data.data.forEach(order => {
          if (order && Array.isArray(order.logs)) {
            const { fees, total } = extractAndSetFees(order.id, order.logs);
            if (fees.length) {
              feesByOrderId[order.id] = fees;
              // Initialize the total for this order id if not already set
              if (!feesTotal[order.id]) {
                  feesTotal[order.id] = 0;
              }
              feesTotal[order.id] += total;
            }
          } else {
            // console.error('Invalid logs in order:', order.logs);
          }
        });

        // Update fees state
        setFeesByOrderId(feesByOrderId);
        setFeesTotal(feesTotal); // Set the total fees
      } else {
        // console.error('Invalid data:', data);
      }
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      dispatch({ upsert: { loading: false } });
    }
  };

  const onGetAvailmentFee = (order) => {
    const { log } = order.logs.find((x) => { return x.status === 'pending' && x.type === 'payment' })

    if (!log) {
      return 0
    }

    const availment = log.find((x) => { return x.id === 0 })

    return availment ? availment.amount : 0;
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    if (params && params.page) {
      loadData();
    }
  }, [params, status, state.reload]);

  useEffect(() => {
    const qry = JSON.parse(state.query);
    const type = qry.success ? 'success' : 'error';
    const msg = qry.success || qry.failed;
    msg && message[type](msg);
  }, []);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='My orders'
      />
      <DataList
        noBottBox
        values={values}
        storeKey='myorder'
        onChange={setParams}
        countLabel='orders'
        dataSource={state.orders}
        searchPlaceholder='Search order code'
        renderItem={order => {
          const addr = order.address || {};
          const lat = addr.lat ? parseFloat(addr.lat) : '';
          const lng = addr.lng ? parseFloat(addr.lng) : '';
          let total = 0;
          order.items.forEach(item => (total += item.pricing.unit_price * item.quantity));
          return (
            <List
              bordered
              className={`${s.ordercompbox} market-order`}
              dataSource={order.items}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={(
                      <Spacer center block>
                        {item.product.name}
                        {item.variation_log.length > 0 && <Variations item={item} noBott view />}
                        <Created prefix='Added' item={item} noAvatar />
                      </Spacer>
                    )}
                    description={(
                      <>
                        <Spacer split block>
                          <Space>
                            <Typography.Text type='secondary'>Quantity:</Typography.Text>
                            <Typography.Text className={s.qty}>
                              {Utils.toMonetary(item.quantity, 0)}
                            </Typography.Text>
                          </Space>
                          <Space align='start'>
                            <Typography.Text type='secondary'>Price:</Typography.Text>
                            <Typography.Text>
                              <span className={s.primary}>₱{Utils.toMonetary(item.pricing.unit_price)}</span>
                            </Typography.Text>
                          </Space>
                          <Space align='start'>
                            <Typography.Text type='secondary'>Fees:</Typography.Text>
                            <Typography.Text>
                              <span className={s.primary}>₱{Utils.toMonetary(feesTotal[order.id])}</span>
                            </Typography.Text>
                          </Space>
                          <Space>
                            <Typography.Text type='secondary'>Sub-total:</Typography.Text>
                            <Typography.Text>
                              ₱{Utils.toMonetary(item.pricing.unit_price * item.quantity)}
                            </Typography.Text>
                          </Space>
                        </Spacer>
                      </>
                    )}
                    avatar={<Avatar size={46} user={item.product} square thumb />}
                  />
                </List.Item>
              )}
              header={(
                <Collapse key={order.id} bordered={false} expandIconPosition='end' accordion activeKey={panel} onChange={setPanel}>
                  <Collapse.Panel
                    key={order.id}
                    className={s.collapseheader}
                    extra={state.screens.lg && <Typography.Text>Total ₱{Utils.toMonetary(total + feesTotal[order.id] + onGetAvailmentFee(order))}</Typography.Text>}
                    header={(
                      <Spacer noBott block align='center' direction='horizontal'>
                        <Avatar size={24} user={order.company} thumb />
                        <Ellipsis style={!state.screens.lg && { width: 'calc(100vw - 130px)' }}>
                          <Tag color={orderGet.toStatusColor(order.status)}>
                            {orderGet.statusToLabel(order.status)}
                          </Tag>
                          {order.company.name}
                        </Ellipsis>
                        {state.screens && state.screens.lg && <Typography.Text type='secondary'>Ordered <Timestamp stamp={order.created_at} /></Typography.Text>}
                      </Spacer>
                    )}
                  >
                    <br />
                    <Desc>
                      <Desc.Item label='Order code'>{order.code}</Desc.Item>
                      <Desc.Item label='Date ordered'><Timestamp stamp={order.created_at} /></Desc.Item>
                      <Desc.Item label='Distributor'>{order.company.name}</Desc.Item>
                      <Desc.Item label='Distributor mobile'>
                        {order.company.mobile ? <a href={`tel:${order.company.mobile.replace('+63', '0')}`}>{order.company.mobile}</a> : '-'}
                      </Desc.Item>
                      <Desc.Item label='Distributor email'>
                        {order.company.email ? <a href={`mailto:${order.company.email}`}>{order.company.email}</a> : '-'}
                      </Desc.Item>
                      <Desc.Item>
                        <Dropdown
                          className='cursor-pointer'
                          disabled={['canceled', 'received'].includes(order.status)}
                          trigger={['click']}
                          menu={{
                            items: [
                              {
                                key: 'retry',
                                label: 'Retry payment',
                                disabled: state.loading || !order.isRetriable,
                                onClick: orderAction('retry', order)
                              },
                              {
                                key: 'cancel',
                                label: 'Cancel order',
                                disabled: state.loading || !order.isCancelable,
                                onClick: orderAction('cancel', order)
                              },
                              {
                                key: 'receive',
                                label: 'Order received',
                                disabled: state.loading || order.status !== 'toreceive',
                                onClick: orderAction('receive', order)
                              }
                            ]
                          }}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            Order options <AiOutlineDown />
                          </a>
                        </Dropdown>
                      </Desc.Item>
                    </Desc>
                    <Desc
                      label='Payment details'
                      extra={(
                        <Tag color={orderGet.toStatusColor(order.status_payment)}>
                          {orderGet.statusToLabel(order.status_payment)}
                        </Tag>
                      )}
                    >
                      <Desc.Item label='Reference No.'>{order.payment[0].code || '-'}</Desc.Item>
                      <Desc.Item label='Method'>{orderGet.methodCodeToName(order.method_payment)}</Desc.Item>
                      <Desc.Item label='Total'>₱{Utils.toMonetary(total)}</Desc.Item>
                      { order.method_payment === 'icl' &&
                        <>
                          <Desc.Item label='Availment Fee'>
                            ₱{Utils.toMonetary(onGetAvailmentFee(order))}
                          </Desc.Item>
                          <Desc.Item label='Grand Total'>
                            ₱{Utils.toMonetary(total + feesTotal[order.id] + onGetAvailmentFee(order))}
                          </Desc.Item>
                        </>
                      }
                    </Desc>
                    <Desc
                      label='Delivery details'
                      extra={(
                        <Tag color={orderGet.toStatusColor(order.status_delivery)}>
                          {orderGet.statusToLabel(order.status_delivery)}
                        </Tag>
                      )}
                    >
                      <Desc.Item label='Reference No.'>{order.delivery.reference_code || '-'}</Desc.Item>
                      <Desc.Item label='Method'>{orderGet.methodCodeToName(order.method_delivery) || '-'}</Desc.Item>
                      <Desc.Item label='Recipient'>
                        {order.recipient.name || '-'}. &nbsp;
                        {order.recipient.mobile || '-'}
                      </Desc.Item>
                      <Desc.Item label='House/Subdivision'>{order.address.housesubd || '-'}</Desc.Item>
                      <Desc.Item label='Zip Code'>{order.address.zipcode || '-'}</Desc.Item>
                      <Desc.Item label='Barangay'>{Utils.toTitleCase(order.address.barangay || '-')}</Desc.Item>
                      <Desc.Item label='Town/City'>{Utils.toTitleCase(order.address.towncity || '-')}</Desc.Item>
                      <Desc.Item label='Province'>{Utils.toTitleCase(order.address.province || '-')}</Desc.Item>
                      <Desc.Item label='Region'>{Utils.toTitleCase(order.address.region || '-')}</Desc.Item>
                      <Desc.Item>
                        <GoogleMap
                          reCenter
                          readOnly
                          theme={Address.theme}
                          values={{ zoom: 16, lat, lng, center: [lat, lng] }}
                        />
                      </Desc.Item>
                    </Desc>
                    <Desc
                      label='Additional Fees'
                    >
                      {feesByOrderId[order.id] ? (
                        feesByOrderId[order.id].map(fee => (
                          <Desc.Item key={fee.id} label={fee.name}>
                            ₱{Utils.toMonetary(fee.amount)}
                          </Desc.Item>
                        ))
                      ) : (
                        <Desc.Item>No additional fees</Desc.Item>
                      )}
                    </Desc>
                  </Collapse.Panel>
                </Collapse>
              )}
            />
          );
        }}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
        ]}
        extra={(
          <Select
            allowClear
            placeholder='Select status'
            className={s.sort}
            onChange={setStatus}
            value={status}
            options={orderGet.typeStatus.order}
          />
        )}
      />
    </Dashboard>
  );
};

export default MyOrders;
