import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, List, Space, Card, Typography, Avatar, Upload, Button, Modal, notification, Table, Input, Tag, Dropdown, Menu } from 'antd';
import { Dashboard, Timestamp, Avatar as Avatahr, Header, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineStop } from 'react-icons/ai';
import { BsCheckCircle } from 'react-icons/bs';
import page from 'page';
import uploadGet from './uploadGet';
import UploadForm from './UploadForm';
import uploadUpdate from './uploadUpdate';
import uploadApprove from './uploadApprove';
import uploadDL from './uploadDL';
import * as XLSX from 'xlsx';
import AWS from 'aws-sdk';
import styles from './uploadView.module.less'; // Import styles

const UploadView = (props) => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [upload, setUpload] = useState([]);
  const [searchText, setSearchText] = useState('');
  const toDrawer = Dashboard.toDrawer(dispatch);
  const [role, setRole] = useState({});

  const loadData = async () => {
    try {
      let apiParams = {};

      if (state.user) {
        if (state.user.type === 'admin') {
          const { status, data } = await uploadGet();
          if (status === 200) {
            setUpload(data);
          }
          setStatus(status);
        } else if (state.user.company_id) {
          apiParams.company_id = state.user.company_id;
          const { status, data } = await uploadGet(apiParams);
          if (status === 200) {
            setUpload(data);
          }
          setStatus(status);
        }
      }
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  const formatDateAgo = (dateTimeUploaded) => {
    const dateUploaded = new Date(dateTimeUploaded);
    const currentDate = new Date();

    const timeDifference = currentDate - dateUploaded;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const columns = [
    { 
      title: 'ID', 
      dataIndex: 'id', 
      key: 'id', 
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Company',
      dataIndex: 'company_id',
      key: 'company_id',
      render: (company_id) => `${company_id.name}`,
    },
    {
      title: 'Uploaded by',
      dataIndex: 'uploader',
      key: 'uploader',
      render: (uploader) => `${uploader.first_name} ${uploader.last_name}`,
    },
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (name, record) => (
        <span className={styles['filename-span']}>
          <a href="#" onClick={() => handleDownload(record.file)}>
            {name}
          </a>
        </span>
      ),
    },

    {
      title: 'Price Group',
      dataIndex: 'pricing_id',
      key: 'pricing_id',
      render: (pricing_id) => `${pricing_id.name}`,
    },
    
    {
      title: 'Uploaded At',
      dataIndex: 'dateTimeUploaded',
      key: 'dateTimeUploaded',
      sorter: (a, b) => new Date(a.dateTimeUploaded) - new Date(b.dateTimeUploaded),
      render: (dateTimeUploaded) => formatDateAgo(dateTimeUploaded),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => {
        let color = '';
        switch (status.toLowerCase()) {
          case 'pending':
            color = 'default';
            break;
          case 'processed':
            color = 'green';
            break;
          case 'failed':
            color = 'red';
            break;
          case 'canceled':
            color = 'grey';
            break;
          default:
            color = 'default';
            break;
        }

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: (text, record) => (
        <span>
          {record.status.toLowerCase() === 'pending' && (
            <Button type="primary" onClick={() => showCancelConfirmation(record)}>Cancel Upload</Button>
          )}
        </span>
      ),
    }
  ];
  const showCancelConfirmation = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel?',
      okText: 'Yes',
      cancelText: 'No',
      okType: 'danger',
      onOk() {
        handleCancel(record);
      },
    });
  };

  const handleDownload = async (fileKey) => {
    try {
      const presignedUrl = await uploadDL({ url: fileKey });
      window.location.href = presignedUrl;
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleCancel = async (record) => {
    try {
      console.log('Sending data for canceling:', {
        id: record.id,
        status: 'Canceled',
        updated_by: state.user ? state.user.id : null,
        company_id: record.company_id,
        fileName: record.fileName
      });

      const response = await uploadUpdate({
        id: record.id,
        status: 'Canceled',
        updated_by: state.user ? state.user.id : null,
        company_id: record.company_id.id,
        fileName: record.fileName
      });
      window.location.reload();
      console.log(`Cancel action for record with ID ${record.id}`, response.data);
    } catch (error) {
      console.error('Error cancelling:', error);
    }
  };

  return (
    <Dashboard viewspin status={status} back="/upload/all">
      <Header
        noTop
        title={
          <>
            <Button shape="circle" onClick={() => window.history.back()} icon={<FiChevronLeft />} />
            <Typography.Title className="no-bott-margin" level={4}>
              File Upload
            </Typography.Title>
          </>
        }
        extra={
          <Button type="primary" onClick={toDrawer(<UploadForm id={role.id} key={role.id} />, 'Upload File')}>
            Upload File
          </Button>
        }
      />
      <Input
        placeholder="Search by ID, Name, Status, or Uploaded At"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <Table
        columns={columns}
        className={`${styles['custom-ant-table']} ${styles['borderless-table']}`}
        dataSource={upload.filter(item =>
          item.id.toString().includes(searchText) ||
          item.fileName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.status.toLowerCase().includes(searchText.toLowerCase()) ||
          formatDateAgo(item.dateTimeUploaded).toLowerCase().includes(searchText.toLowerCase())
        )}
        onChange={(pagination, filters, sorter) => {
        }} 
      />
    </Dashboard>
  );
};

export default UploadView;
