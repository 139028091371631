import React, { useContext, useState, useEffect } from 'react';
import { Upload, Form, Button, message, Progress, Select ,notification} from 'antd';
import { Context } from 'shookt';
import { UploadOutlined } from '@ant-design/icons';
import uploadPost from './uploadPost';
import page from 'page';
import * as XLSX from 'xlsx';
import  UploadPricingGet from './UploadPricingGet';
import uploadCheck from './uploadCheck';
const { Option } = Select;

const UploadForm = (props) => {
  const [state, dispatch] = useContext(Context);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [pricingOptions, setPricingOptions] = useState([]);
  const [uploadErrors, setUploadErrors] = useState('');

  const parseExcelData = (fileContent) => {
    // Convert fileContent to binary string
    const binaryString = atob(fileContent.split(',')[1]);
  
    // Convert binary string to array buffer
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
  
    // Parse Excel data
    const workbook = XLSX.read(uint8Array, { type: 'array' });
    const sheetName = workbook.SheetNames[0]; // Assuming there's only one sheet
    const worksheet = workbook.Sheets[sheetName];
    const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
    // Remove header row if needed
    if (excelData.length > 0 && Array.isArray(excelData[0])) {
      excelData.shift();
    }
  
    // Filter out null values and empty rows
    const filteredData = excelData.filter(row => {
      return row.some(cell => cell !== null && cell !== '');
    });
  
    return filteredData;
  };

  useEffect(() => {
    
    async function fetchPricingOptions() {
      try {
        let params = {};
        if (state.user && state.user.company_id) {
          params.company_id = state.user.company_id; 
         }
        
        const response = await UploadPricingGet(params); // Fetch pricing options with params
        if (response.status === 200) {
          setPricingOptions(response.data); // Set pricing options in state
        }
      } catch (error) {
        console.error('Error fetching pricing options:', error);
      }
    }
    fetchPricingOptions();
  }, [state.user]);

  const { Dragger } = Upload;

  const handleUpload = async () => {
    try {
     console.log('Uploading...');

      const pendingStatusResponse = await checkPendingStatus(selectedOption);
      // ('stat',pendingStatusResponse);
      if (pendingStatusResponse.data && pendingStatusResponse.data.length > 0) {
        const pendingUploads = pendingStatusResponse.data.filter(item => item.status === "Pending");
        if (pendingUploads.length > 0) {
          // Display notification and prevent upload
          notification.warning({
            message: 'Cannot Queue Approval',
            description: 'A file is being processed at the moment. You can only queue one approval at a time.'
          });
          return;
        }
      }
      if (!fileList || fileList.length === 0 || !fileList[0]) {
        throw new Error('Please select a file to upload.');
      }
  
      if (!selectedOption) {
        throw new Error('Please select an option.');
      }
  
      const fileContent = await readFileContent(fileList[0]);
  
      // Parse Excel data
      const excelData = parseExcelData(fileContent);
  
      // Check for special characters in all columns
      const specialCharRegex = /[^\w\s().'%&-]/;
      const rowsWithSpecialChars = excelData.filter(row => {
        return row.some(cell => specialCharRegex.test(cell));
      });
  
     // Check for duplicated values
     const uniqueEntries = new Set();
     const duplicateRows = excelData.filter(row => {
       const combinedEntry = row.join('-');
       if (uniqueEntries.has(combinedEntry)) {
         return true;
       } else {
         uniqueEntries.add(combinedEntry);
         return false;
       }
     });
 
     if (rowsWithSpecialChars.length > 0 || duplicateRows.length > 0) {
       const errors = [];
 
       rowsWithSpecialChars.forEach(row => {
         errors.push(`Special character value on Row ${excelData.indexOf(row) + 2}`);
       });
 
       duplicateRows.forEach(row => {
         errors.push(`Duplicated value on Row ${excelData.indexOf(row) + 2}`);
       });
 
       setUploadErrors(errors.join('\n'));
       return;
     } else {
       setUploadErrors('');
     }
      const jsonData = JSON.stringify({
        fileContent: fileContent,
        fileName: fileList[0].name,
        dateTimeUploaded: new Date().toISOString(),
        uploader: state.user ? state.user.id : null,
        company_id: state.user ? state.user.company_id : null,
        pricing_id: selectedOption
      });

      console.log('Sending file as JSON:', jsonData);
      const response = await uploadPost(jsonData, setProgress, setUploading);

      if (response.data) {
        const responseData = response.data;

        if (props.id) {
          dispatch({ upsert: { reload: true } });
        } else {
          page(`/upload/all`);
          window.location.reload();
        }

        dispatch({ delete: ['drawer'] });
        message.success('File uploaded and data saved successfully.');
      } else {
        console.error('Error saving data:', response);
        throw new Error('Failed to save data.');
      }
    } catch (error) {
      console.error('Error during upload:', error);
      setProgress(0);
      setUploading(false);
      message.error(error.message);
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const checkPendingStatus = async (pricingId) => {
    try {
      const response = await uploadCheck(pricingId); // Pass pricingId as a parameter
      return response;
    } catch (error) {
      console.error('Error checking pending status:', error);
      return { data: null }; // Return null if there's an error
    }
  };

  const uploadProps = {
    onRemove: () => {
      setFileList(null);
    },
    beforeUpload: (file) => {
      const fileType = file.type;
      const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];

      if (!allowedTypes.includes(fileType)) {
        message.error('You can only upload Excel files (.xlsx or .xls).');
        return false;
      }

      setFileList([file]); // Ensure fileList is an array with a single file
      return false;
    },
    fileList: fileList ? [fileList] : [], // Ensure fileList is an array with a single file
    name: 'file',
  };

  return (
    <div style={{ margin: '50px' }}>
      <Form>
        <span style={{ borderBottom: '1px solid black', display: 'block', marginBottom: '16px' }}>Please select pricing</span>
        <Form.Item>
          <Select 
            value={selectedOption} 
            onChange={value => setSelectedOption(value)} 
            style={{ width: '100%' }} 
            placeholder="Select an option"
          >
            {pricingOptions.map(option => (
              <Option key={option.id} value={option.id}>{option.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Dragger {...uploadProps} showUploadList={false}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
      {fileList && (
        <div style={{ marginTop: 16 }}>
          <h3>Selected File:</h3>
          <p>
            <strong>Name:</strong> {fileList[0].name} | <strong>Size:</strong>{' '}
            {`${(fileList[0].size / 1024).toFixed(2)} KB`}
          </p>
        </div>
      )}
      {fileList && (
        <div style={{ marginTop: 16 }}>
          <Progress percent={progress} status={uploading ? 'active' : 'success'} />
        </div>
      )}
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={!fileList || uploading || !selectedOption}
        loading={uploading}
        style={{ marginTop: 16 }}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>

      <div>
    Note: please refrain from using the following special characters such as :
    <span style={{ fontWeight: 'bold' }}> 
    <span>(</span> 
      <span style={{ color: 'red' }}>@</span> 
      <span style={{ color: 'red' }}>!</span> 
      <span style={{ color: 'red' }}>#</span> 
      <span style={{ color: 'red' }}>^</span> 
      <span style={{ color: 'red' }}>*</span> 
      <span style={{ color: 'red' }}>+</span> 
      <span style={{ color: 'red' }}>=</span> 
      <span style={{ color: 'red' }}>&#123;</span> 
      <span style={{ color: 'red' }}>&#125;</span> 
      <span style={{ color: 'red' }}>:</span> 
      <span style={{ color: 'red' }}>;</span> 
      <span style={{ color: 'red' }}>&lt;</span> 
      <span style={{ color: 'red' }}>&gt;</span> 
      <span style={{ color: 'red' }}>?</span> 
      <span style={{ color: 'red' }}>|</span> 
      <span style={{ color: 'red' }}>~</span> 
      <span style={{ color: 'red' }}>`</span> 
      <span style={{ color: 'red' }}>\</span> 
      <span>)</span> 
    </span> 
  </div>

      {uploadErrors && (
        <div style={{ marginTop: 16, color: 'red' }}>
          <p>Error(s) encountered:</p>
          <pre>{uploadErrors}</pre>
        </div>
      )}
    </div>
  );
};

export default UploadForm;
