import axios from 'axios';
import { Utils } from 'shookt';

const availmentGet = async params => {
  try {
    const response = await axios({
      method: 'get',
      url: `/availment?${Utils.qs(params)}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default availmentGet;
