import { useContext, useEffect, useState } from 'react';
import { List, Button, Empty, ConfigProvider, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Context, Utils } from 'shookt';
import { Header, Desc } from '../';
import bankoGet from './bankoGet';

const Banko = props => {
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  let link = 'linku';
  let user = props.user || state.user || {};
  if (props.company) {
    link = 'linkc';
    user = props.company;
  }

  const linkBanko = () => {
    window.location.replace(`/api/banko?state=${link}`);
  };

  const loadData = async () => {
    setLoading(true);

    const query = {};
    if (props.company) {
      query.cid = props.company.id;
    } else {
      query.uid = true;
    }
    const { status, data } = await bankoGet(query);
    if (status === 200 && data.accounts.length) {
      const { accounts, availment } = data;

      if (props.company) {
        dispatch({ upsert: { [`bankoAccounts${props.company.id}`]: accounts } });
      } else {
        dispatch({ upsert: { bankoAccounts: accounts } });
      }

      if (availment) {
        dispatch({ upsert: { availmentAccount: availment, hasAvailment: true } });
      }
    } else {
      message.error('Unable to load BanKo account details at this time. Please try again later.');
    }

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (props.autoload) {
        await loadData();
      }
    })();
  }, []);

  return (
    <>
      {
        props.noHead || (
          <Header
            noBottom
            title='BanKo Accounts'
            extra={(
              <Button
                type='link'
                onClick={linkBanko}
              >
                {user.banko ? 'Re-link BanKo account' : 'Link BanKo account'}
              </Button>
            )}
          />
        )
      }
      <ConfigProvider renderEmpty={() => (
        <Empty
          imageStyle={{ display: 'none' }}
          description={user.banko ? 'BanKo account linked.' : 'No BanKo account linked.'}
        >
          <Button
            type={user.banko ? 'default' : 'primary'}
            onClick={user.banko ? loadData : linkBanko}
          >
            {user.banko ? 'Load BanKo account details' : 'Link BanKo Account'}
          </Button>
        </Empty>
      )}
      >
        <List
          itemLayout='vertical'
          dataSource={props.company ? state[`bankoAccounts${props.company.id}`] : state.bankoAccounts}
          loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                description={(
                  <Desc>
                    <Desc.Item label='Account name'>
                      {item.name}
                    </Desc.Item>
                    <Desc.Item label='Account number'>
                      {item.number}
                    </Desc.Item>
                    <Desc.Item label='Current balance'>
                      ₱{Utils.toMonetary(item.balance)}
                    </Desc.Item>
                  </Desc>
                )}
              />
            </List.Item>
          )}
        />
      </ConfigProvider>
    </>
  );
};

Banko.loadAccounts = async (companyId, userId) => {
  const query = {};
  if (companyId) {
    query.cid = companyId;
  } else {
    query.uid = userId || true;
  }
  const { data } = await bankoGet(query);
  return data || [];
};

export default Banko;
