import axios from 'axios';

const uploadGet = async (params) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/upload',
      params: params, // Include parameters in the GET request
    });
    return response;
  } catch ({ response }) {
    return response;
    // Handle errors if needed
  }
};

export default uploadGet;
