import { useEffect, useContext, useState } from 'react';
import { Button, List, Typography, Space, Radio, Input, Form, Row, Col, Modal } from 'antd';
import { Dashboard, Address, Header, Spacer, Avatar, Variations, Desc, Banko, Availment,ServiceFee} from '../../components';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Context, GoogleMap, Mobile, Erratum, Utils } from 'shookt';
import page from 'page';
import s from './market.module.less';
import marketItemGet from './marketItemGet';
import marketOrderPost from './marketOrderPost';
import CheckoutAddress from './CheckoutAddress';
import availmentGet from '../../components/availment/availmentGet';

const Checkout = props => {
  const [state, dispatch] = useContext(Context);
  const [total, setTotal] = useState(0);
  const [clear, setClear] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [method, setMethod] = useState('');
  const [otherPayment, setOtherPayment] = useState('')
  const [hasIssue, setHasIssue] = useState([]);
  const [address, setAddress] = useState(state.user.address || {});
  const toDrawer = Dashboard.toDrawer(dispatch);
  const [form] = Form.useForm();
  const { user } = state;
  const zoom = address.lat ? 16 : 9;
  const lat = address.lat ? parseFloat(address.lat) : '';
  const lng = address.lng ? parseFloat(address.lng) : '';
  const [isAvailmentModalOpen, setIsAvailmentModalOpen] = useState(false);
  const [isUsingAvailment, setIsUsingAvailment] = useState(false);
  const [isInsuficientForAvailment, setIsInsuficientForAvailment] = useState(false);
  const [rawTotal, setRawTotal] = useState(0);
  const [availmentFee, setAvailmentFee] = useState(0);
  const [availmentFeeAmount, setAvailmentFeeAmount] = useState(0);
  const [paramCompany_id, setParamCompany_id] = useState(null);
  const [paramTotal, setparamTotal] = useState(null);
  const [feesTotal, setFeesTotal] = useState(0);
  const [feesArray, setFeesArray] = useState([]);

  const onFinish = async ({ recipient }) => {
    dispatch({ delete: ['error'] });
    if (!mobile) {
      return dispatch({ upsert: { error: 'Please enter recipient mobile number.' } });
    }
    if (!method) {
      return dispatch({ upsert: { error: 'Please select a payment method.' } });
    }
    if (!clear) {
      return dispatch({ upsert: { error: 'Some distributors do not support this payment method. You have to remove them to proceed with this method.' } });
    }
    if (!Object.keys(address).length) {
      return dispatch({ upsert: { error: 'Delivery address is required.' } });
    }
    if (!address.lat || !address.lng) {
      return dispatch({ upsert: { error: 'Please edit your address and pin the delivery location.' } });
    }
    if (method === 'banko' && !state.bankoAccounts.length) {
      return dispatch({ upsert: { error: 'Please link your Banko Account first.' } });
    }
    if (method === 'icl') {
      if (rawTotal < 500) {
        return dispatch({ upsert: { error: 'Availment minimum amount must be ₱500.' } });
      }

      if (state.availmentAccount.avlblLmtAmt < rawTotal) {
        return dispatch({ upsert: { error: 'Available Limit is less than total amount.' } });
      }

      if (state.availmentAccount.arrStsCd === 'S') {
        return dispatch({ upsert: { error: 'Availment Account is Suspended.' } });
      }
    }
    
    delete address.id;
    const directbuy = state.buy && state.buy[0].payload;
    const { status } = await marketOrderPost({ method, mobile, recipient, directbuy, ...address, fees: feesArray, });
    if (status === 200) {
      dispatch({ upsert: { loading: true } });
      // TODO: add other payment methods here
      if (method === 'banko') {
        window.location.replace('/api/banko?state=transfer');
      } else if (method === 'icl') {
        window.location.replace('/api/banko?state=icl');
      } else {
        dispatch({ upsert: { loading: false } });
        page('/myorders');
      }
    }
  };

  const showModal = (status) => {
    if (status === 200) {
      setIsAvailmentModalOpen(true);
    } else {
      setIsInsuficientForAvailment(true);
    }
  };

  const getMethod = (value) => {
    if (value !== 'icl' && availmentFee) {
      setAvailmentFee(0);
      setTotal(total);
    }
  }

  const handleConfirm = async () => {
    setIsAvailmentModalOpen(false);
    setIsUsingAvailment(true);
    setAvailmentFee(availmentFeeAmount);

    setTotal(total);

    const data = {
      id: 0,
      name: 'feeAmt',
      amount: availmentFeeAmount
    }

    setFeesArray([ ...feesArray, data ])
  };

  const onGetAvailmentFee = async (txAmt) => {
    const query = { txAmt }
    const res = await availmentGet(query);

    const { data } = res;
    const { feeAmt } = data;

    setAvailmentFeeAmount(feeAmt);
    showModal(data.statusCode);
  }

  const handleOk = () => {
    setIsAvailmentModalOpen(false);
    setIsUsingAvailment(true);
    page('/profile');
  };

  const handleCancel = () => {
    setIsAvailmentModalOpen(false);
    setIsInsuficientForAvailment(false);

    setMethod(null);
  };

  useEffect(() => {
    if (state.delivadd) {
      setAddress(state.delivadd);
    }
  }, [state.delivadd]);

  useEffect(() => {
    user.mobile && setMobile(user.mobile);
    form.setFieldsValue({ recipient: Utils.formatName(user, true) });
    let hasValidCompanyId = false;
    if (state.buy) {
      setTotal(state.buy[0].total);
      setRawTotal(state.buy[0].total);
      if (!hasValidCompanyId && state.buy[0].id != null) {
        setParamCompany_id(state.buy[0].id); // Set only once
        setparamTotal(state.buy[0].total);
        hasValidCompanyId = true; // Prevent further updates
      }
    } else {
      (async () => {
        const { data } = await marketItemGet('all');
        const cart = {};
        if (data.length) {
          let total = 0;
          data.forEach(item => {
            if (!hasValidCompanyId && item.company_id != null) {
              setParamCompany_id(item.company_id); // Set only once
              hasValidCompanyId = true; // Prevent further updates
            }
            cart[item.company_id] = cart[item.company_id] || { ...item.company, items: [], total: 0 };
            const selections = JSON.parse(JSON.stringify(item.variations).toLowerCase());
            const price = item.pricing.filter(p => {
              let match = true;
              for (const s in selections) {
                if (p.variations[s] !== selections[s]) {
                  match = false;
                }
              }
              return match;
            })[0];
            if (price) {
              item.price = parseFloat(price.unit_price);
              total += item.price * item.quantity;
              cart[item.company_id].total += item.price;
              cart[item.company_id].items.push(item);
              setparamTotal(total);
            }
          });
          setTotal(total);
          setRawTotal(total);
          return dispatch({ upsert: { cart: Object.keys(cart).map(id => cart[id]) } });
        } else {
          page('/mycart');
        }
        dispatch({ upsert: { cart: [] } });
      })();
    }
  }, []);
  
  const handleFeesTotal = (totalFees,fees) => {
    // Convert totalFees to a number if it's not already
    const parsedFees = parseFloat(totalFees) || 0;
    setFeesTotal(parsedFees);
    setFeesArray(fees);
  };

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Checkout'
        extra={<Button href='/mycart'>Go back</Button>}
      />
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label='Recipient name'
              name='recipient'
              rules={[{
                required: true,
                whitespace: true,
                pattern: '^[a-zA-ZñÑ., ]*$',
                message: 'Please enter a recipient name'
              }]}
            >
              <Input
                minLength={1}
                maxLength={100}
                placeholder='Recipient name'
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label='Recipient mobile'>
              <Mobile value={mobile} onPhone={setMobile} country='ph' className='ant-input' noAutoFocus />
            </Form.Item>
          </Col>
        </Row>
        <Header
          noTop
          noBott
          title='Delivery address'
          extra={(
            <Button type='link' onClick={toDrawer(<CheckoutAddress address={address} />, 'Change delivery address')}>
              Edit
            </Button>
          )}
        />
        {
          Object.keys(address).length !== 0 && (
            <Desc>
              <Desc.Item label='House/Subdivision'>
                {address.housesubd || '-'}
              </Desc.Item>
              <Desc.Item label='Zip Code'>
                {address.zipcode || '-'}
              </Desc.Item>
              <Desc.Item label='Barangay'>
                {Utils.toTitleCase(address.barangay || '-')}
              </Desc.Item>
              <Desc.Item label='Town/City'>
                {Utils.toTitleCase(address.towncity || '-')}
              </Desc.Item>
              <Desc.Item label='Province'>
                {Utils.toTitleCase(address.province || '-')}
              </Desc.Item>
              <Desc.Item label='Region'>
                {Utils.toTitleCase(address.region || '-')}
              </Desc.Item>
              <Desc.Item>
                <GoogleMap
                  theme={Address.theme}
                  values={{ zoom, lat, lng, center: [lat, lng] }}
                  reCenter
                  readOnly
                />
                <br />
              </Desc.Item>
            </Desc>
          )
        }
        {
          !Object.keys(address).length && (
            <Form.Item className='text-center'>
              <Typography.Paragraph type='secondary'>
                No delivery address set.
              </Typography.Paragraph>
              <Button type='primary' onClick={toDrawer(<CheckoutAddress address={address} />, 'Set delivery address')}>
                Set a delivery address
              </Button>
            </Form.Item>
          )
        }
        <Header title='Payment method' noTop noBott />
        <Radio.Group
          value={method}
          onChange={e => {
            setMethod(e.target.value);
            getMethod(e.target.value);
            dispatch({ delete: ['error'] });
            const items = state.buy || state.cart;
            const isClear = items.filter(i => !i.payment_methods.includes(e.target.value));
            setHasIssue(isClear.map(c => c.id));
            if (isClear.length) {
              dispatch({ upsert: { error: 'Some distributors do not support this payment method. You have to remove them to proceed with this method.' } });
            }
            setIsUsingAvailment(false);
            setOtherPayment(null);
            if (e.target.value === 'icl' && !isClear.length) {
              onGetAvailmentFee(rawTotal + feesTotal);
            }
            setClear(isClear.length === 0);
          }}
          options={[
            { label: 'BanKo', value: 'banko' },
            { label: 'InstaCashKoLine', value: 'icl', disabled: false },
            { label: 'Cash on delivery', value: 'cod' },
            { label: 'Others', value: 'others' }
          ]}
        />
        {method === 'banko' && <Banko autoload noHead />}
        {method === 'icl' && isUsingAvailment && <Availment autoload noHead />}
        {method === 'icl' && !isUsingAvailment &&
          <>
            <Modal open={isAvailmentModalOpen} onOk={handleConfirm} onCancel={handleCancel} closable={false} centered okText='Confirm'>
              You are drawing from your InstaCashKoLine which has an availment fee of {availmentFeeAmount ? availmentFeeAmount.toFixed(2) : 0.00} of the availed amount. Would you like to continue? If yes, click Confirm button, if No choose other payment option.
            </Modal>
            <Modal open={isInsuficientForAvailment} onCancel={handleCancel} closable={false} centered okButtonProps={{ style: { display: 'none' } }}>
              Ka-BanKo, need mo ba ng extra cash sa negosyo? Avail na ng InstaCashKo Credit Line! Mabilis at walang patong na interest! Punta na sa BanKo branch ngayon.
            </Modal>
          </>
        }
        {method === 'others' && 
          <>
            <Header title='Other Payments' noTop noBott style={{ paddingTop: '12px' }} />
            <Radio.Group
              value={otherPayment}
              onChange={e => {
                setOtherPayment(e.target.value);
                dispatch({ delete: ['error'] });
                const items = state.buy || state.cart;
                const isClear = items.filter(i => !i.payment_methods.includes(e.target.value));
                setHasIssue(isClear.map(c => c.id));
                if (isClear.length) {
                  dispatch({ upsert: { error: 'Some distributors do not support this payment method. You have to remove them to proceed with this method.' } });
                }
                setClear(isClear.length === 0);
              }}
              options={[
                { label: 'BPI', value: 'bpi' },
                { label: 'GCash', value: 'gcash' },
                { label: 'Maya', value: 'maya' },
              ]}
            />
          </>
        }
        <Header
          level={5}
          title='Order item/s'
          extra={<span className={s.total}>Total ₱{Utils.toMonetary(rawTotal)}</span>}
        />
        <List
          dataSource={state.buy || state.cart}
          renderItem={company => (
            <List
              bordered
              style={hasIssue.includes(company.id) ? { border: '1px solid #f88' } : {}}
              className={s.cartcompbox}
              dataSource={company.items}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={(
                      <Spacer center block>
                        {item.product.name}
                        {item.variation_log.length > 0 && <Variations item={item} noBott view />}
                      </Spacer>
                    )}
                    description={(
                      <>
                        <Spacer split block>
                          <Space>
                            <Typography.Text type='secondary'>
                              Quantity:
                            </Typography.Text>
                            <Typography.Text className={s.qty}>
                              {Utils.toMonetary(item.quantity, 0)}
                            </Typography.Text>
                          </Space>
                          <Space align='start'>
                            <Typography.Text type='secondary'>
                              Price:
                            </Typography.Text>
                            <Typography.Text>
                              {item.price && (<span className={s.primary}>₱{Utils.toMonetary(item.price)}</span>)}
                              {!item.price && (<>No price yet</>)}
                            </Typography.Text>
                          </Space>
                          {
                            item.price && (
                              <Space>
                                <Typography.Text type='secondary'>
                                  Sub-total:
                                </Typography.Text>
                                <Typography.Text>
                                  ₱{Utils.toMonetary(item.price * item.quantity)}
                                </Typography.Text>
                              </Space>
                            )
                          }
                        </Spacer>
                      </>
                    )}
                  />
                </List.Item>
              )}
              header={(
                <Spacer noBott block align='center' direction='horizontal'>
                  <Avatar size={24} user={company} borderColor={hasIssue.includes(company.id) ? '#f88' : '#fff'} thumb />
                  {company.name}
                </Spacer>
              )}
            />
          )}
        />

        {method === 'icl' && isUsingAvailment && 
          <>
            <Header
              noTop
              noBott
              title='Availment Fee'
              extra={(
                <div style={{ textAlign: 'right' }}>
                  <Spacer direction='horizontal' align='center'>
                    <span>₱{Utils.toMonetary(availmentFee)}</span>
                  </Spacer>
                </div>
              )}
            />
          </>
        }

      <ServiceFee params={paramCompany_id} onFeesTotal={handleFeesTotal}  paramTotal={paramTotal} />
        <Header
          noTop
          level={3}
          extra={(
            <div style={{ textAlign: 'right' }}>
              <Erratum />
              <Spacer direction='horizontal' align='center'>
                <span className={s.total}>Total ₱{Utils.toMonetary(total + feesTotal + availmentFee)}</span>
                <Button href='/mycart'>Back</Button>
                <Button
                  block
                  icon={<ShoppingCartOutlined />}
                  type={hasIssue.length ? 'danger' : 'primary'}
                  htmlType='submit'
                  disabled={state.loading}
                  loading={state.loading}
                >
                  Place Order
                </Button>
              </Spacer>
            </div>
          )}
        />
      </Form>
    </Dashboard>
  );
};

export default Checkout;
