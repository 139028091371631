import page from 'page';
import FeesView from './FeesView';
import FeesList from './FeesList';

export const feesRedirect = {
  path: '/fees',
  priv: 'fees',
  handler: () => page.redirect('/fees/all')
};

export const feesListRoute = {
  path: '/fees/all',
  priv: 'fees',
  handler: props => <FeesList {...props} />
};

export const feesViewRoute = {
  path: '/fees/:id',
  priv: 'fees',
  handler: props => <FeesView {...props} />
};
